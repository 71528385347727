<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <button aria-label="Close" class="close" @click="hideErrorMsgModal()">
          x
        </button>
      </template>
      <template #footer>
        <div>
          <CButton :color="modalColor" @click="hideErrorMsgModal()">Ok</CButton>
        </div>
      </template>

      <div class="text-center">
        <h5>{{ modalContent }}!</h5>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Modal",
  data() {
    return {
      isShow: this.isShowPopup,
    };
  },
  watch: {
    isShowPopup(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    ...mapGetters(["showErrorMsgModal"]),
    isShowPopup() {
      return this.showErrorMsgModal?.isShowPopup || false;
    },
    modalTitle() {
      return this.showErrorMsgModal?.modalTitle || null;
    },
    modalColor() {
      return this.showErrorMsgModal?.modalColor || null;
    },
    modalContent() {
      return this.showErrorMsgModal?.modalContent || null;
    },
    isShowModal: {
      get() {
        return this.isShow;
      },
      set(isShowPopup) {
        this.isShow = isShowPopup;
      },
    },
  },
  methods: {
    ...mapActions(["hideErrorMsgModal"]),
  },
};
</script>
